// 获取字典的公用方法
import VueCookies from 'vue-cookies'; //cookie
import { CommonDict } from '@/request/Dictionary/api.js'; // 字典接口

// 获取全部字典
export function getAllDict(code) {
  let data = [];
  CommonDict({
    code: code,
  }).then(res => {
    if (res.data.code === 200) {
      data = res.data.result;
      VueCookies.set(code, res.data.result, '0');
      return data;
    } else {
      console.log('获取字典报错', code);
    }
  });
}
