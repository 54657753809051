<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getAllDict } from '@/utils/dict.js';
export default {
  created() {
    let self = this;
    // 判断是否登录
    // 可以不登录就打开的页面:登录、注册、忘记密码、重置密码
    let noTokenUrl = ['#/Login', '#/Register', '#/Forget', '#/Reset'];
    //获取登录状态
    if (this.$cookies.get('token')) {
      //有登录状态，不动
      this.loginType = true;
    } else {
      if (!noTokenUrl.includes(window.location.hash)) {
        //没有登录状态，并且需要登录，去登录页
        this.loginType = false;
        window.location.href = '#/Login';
      }
    }
    // 获取系统内用到的各类字典
    if (!this.$cookies.get('racing_track')) {
      //获取赛道字典
      getAllDict('racing_track');
    }
    if (!this.$cookies.get('registration_type')) {
      //获取登记类型字典
      getAllDict('registration_type');
    }

    //i18n多语言组件的引入与判断
    if (self.$cookies.get('lang') == null) {
      // 根据用户浏览器语言判断加载语言
      var userLang = navigator.language || navigator.userLanguage;
      console.log(userLang);
      //获取浏览器的语言
      if (userLang.substr(0, 3) == 'zh-') {
        // alert('中文')
        self.$i18n.locale = 'zh';
        self.$cookies.set('lang', 'zh');
      } else {
        // alert('英文')
        self.$i18n.locale = 'en';
        self.$cookies.set('lang', 'en');
      }
    } else {
      //根据用户设置的语言加载语言
      self.$i18n.locale = self.$cookies.get('lang');
    }
  },
  methods: {},
};
</script>

<style></style>
