import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //设定值
    JxjData: [], //金轩奖表单数据
  },
  getters: {},
  mutations: {
    //修改值 通过这里的方法，给state赋值，全局使用
    setJxjData(state, payload) {
      //校验数组内是否存在
      let exist = false; //是否存在
      state.JxjData.forEach((item, index) => {
        //存在，覆盖
        if (item.id == payload.id) {
          state.JxjData[index] = payload;
          exist = true;
        }
      });
      //不存在，添加
      if (!exist) {
        state.JxjData.push(payload);
      }
      // console.log("🚀 ~ vuex setJxjData JxjData:",  state.JxjData);
    },
    //修改值 通过这里的方法，给state赋值，全局使用
    assiJxjData(state, payload) {
      //校验数组内是否存在
      state.JxjData = payload;
      // console.log("🚀 ~ vuex assiJxjData JxjData:",  state.JxjData);
    },
  },
  actions: {
    //异步修改值
  },
  modules: {
    //模块
  },
});
